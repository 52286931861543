import { iPhoto } from '../types/Photo';
import { iPhotoSet } from '../types/PhotoSet';

export async function get<T>(url: string): Promise<Promise<T> | null> {
	try {
		const response = await fetch(`/api/${url}`);
		const data: T = await response.json();

		return data;
	}
	catch (e) {
		console.log(e);
		return null;
	}
}

export async function post<T, D>(url: string, data: D): Promise<Promise<T> | null> {
	try {
		const response = await fetch(`/api/${url}`, { method: 'post', body: JSON.stringify(data) });

		const responseData: T = await response.json();

		return responseData;
	}
	catch (e) {
		console.log(e);
		return null;
	}
}


const getImg = (photoSet: iPhotoSet, photo: iPhoto, subDir = '') => {
	if (!(photo?.name)) {
		console.warn(photoSet.publicPath);
		return undefined;
	}

	const pathArr: string[] = ['/api/photos', photoSet.publicPath, subDir, photo.name].filter(p => p !== '');

	return pathArr.join('/');
};

export const getImgSrc = (photoSet: iPhotoSet, photo: iPhoto) => getImg(photoSet, photo);
export const getProgressiveSrc = (photoSet: iPhotoSet, photo: iPhoto) => getImg(photoSet, photo, 'progressive');
export const getThumbSrc = (photoSet: iPhotoSet, photo: iPhoto) => getImg(photoSet, photo, 'thumbs');

export const getShortenedName = (set: iPhotoSet) => {
	const nameArr = set.name.split(' > ');
	const len = nameArr.length;

	const shortennedName = nameArr.map((n: any) => n.trim()).slice(len - 2, len);

	return shortennedName;
};