import { getShortenedName, getThumbSrc } from '../../utils';
import { iPhoto } from '../../types/Photo';
import { iPhotoSet } from '../../types/PhotoSet';

export function PublishItem({ set, photo }: { set: iPhotoSet; photo: iPhoto }) {
	const src = getThumbSrc(set, photo);

	return (
		<img
			title={photo.name}
			key={set.name}
			style={{
				maxHeight: '100%',
				maxWidth: '100%',
				//objectFit: 'cover',
			}}
			src={src}
		/>
	);
}
