import { useAuth0 } from '@auth0/auth0-react';

export interface IAdminRoute {
	component: any;
	scopes?: Array<string>;
}

export function AdminRoute(props: IAdminRoute) {
	const { component: Component, scopes = ['admin'], ...rest } = props;
	const { user } = useAuth0();
	const roles = user?.['http://localhost:3000/roles'] ?? [];
	console.log(roles);

	if (!roles.includes('admin')) {
		return (
			<h1>
				{JSON.stringify(user)}
				Unauthorized !
			</h1>
		);
	}
	return <Component {...rest} />;
}
