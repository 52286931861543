import { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
	selectPhotoSet,
	//setPhoto,
	setPhotoSet,
	//selectCurrentPhoto,
	//setPhotoByName,
} from './photoSetSlice';
import styles from './PhotoSet.module.scss';
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';

import { Gallery } from '../gallery/Gallery';
import { iPhoto } from '../../types/Photo';
import { PhotoViewer } from '../photo/PhotoViewer';
import { PhotoSetInfo } from './PhotoSetInfo';

type setProps = {
	hash: string;
};

export function PhotoSet() {
	const dispatch = useAppDispatch();

	const set = useAppSelector(selectPhotoSet) ?? null;
	const { hash = '' } = useParams();

	useEffect(() => {
		dispatch(setPhotoSet(hash));
	}, [hash]);

	return set ? (
		<div className={styles.PhotoSet}>
			<PhotoSetInfo set={set} />

			<Gallery PhotoViewerComponent={PhotoViewer} />
		</div>
	) : null;
}
