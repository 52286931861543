import { useAppDispatch } from '../../app/hooks';
import styles from './PhotoSet.module.scss';

import { PhotoSet } from '../../types/PhotoSet';
import { getThumbSrc } from '../../utils/index';

export function PhotoSetInfo({ set }: { set: PhotoSet }) {
	const { name } = set;
	const src = getThumbSrc(set, set.photoList[0]);

	return (
		<div className={styles.PhotoSet}>
			<div className={styles.PhotoSetInfo}>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '100px 1fr',
						gap: '15px',
					}}
				>
					<img
						src={src}
						className={styles.PhotoSetThumb}
						style={{
							height: '100px',
							width: '100px',
							objectFit: 'cover',
						}}
					/>
					<div>
						<h4
							className='card-title'
							style={{ textTransform: 'capitalize' }}
						>
							{name.split(' > ')[0]}
						</h4>
						<h6
							className='card-subtitle mb-2 text-muted'
							style={{ textTransform: 'capitalize' }}
						>
							{name.split(' > ').slice(1).join(' ')}
						</h6>
					</div>
				</div>
			</div>
		</div>
	);
}
