import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectPhotoSets, setPhotoSets } from '../photoSets/photoSetsSlice';
import { PhotoSet } from '../../types/PhotoSet';
import { SetItem } from './SetItem';
import { useNavigate, useParams } from 'react-router-dom';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

import { PhotoSetInfo } from '../photoSet/PhotoSetInfo';
import { getThumbSrc } from '../../utils';
import { Photo } from '../../types/Photo';
import moment from 'moment';

type ScheduledPhoto = {
	dateKey: string;
	date: moment.Moment;
	photo: Photo;
};

export function Scheduler() {
	const dispatch = useAppDispatch();

	const [filter, setFilter] = useState('');
	const [showSetSelector, setShowSetSelector] = useState(false);

	useEffect(() => {
		dispatch(setPhotoSets());
	}, []);

	const sorted = getUnpublishedPhotos() ?? [];

	const scheduled: ScheduledPhoto[] = schedulePhotos(sorted);
	const monthlyPhotos = groupPhotosByMonth(scheduled);

	//const [date, setDate] = useState(moment().format('YYYY-MM'));

	// const photos = monthlyPhotos[date] ?? [];
	// const lookup = getMonthlyPhotoLookup(photos);

	return Object.entries(monthlyPhotos).map(([month, photos]) => {
		if (photos.length === 0) {
			return;
		}

		const lookup = getMonthlyPhotoLookup(photos);

		return (
			<>
				{/* <MonthPicker date={date} setDate={setDate} /> */}
				<Calendar date={month} monthlyPhotos={lookup} />
			</>
		);
	});
}

function getMonthlyPhotoLookup(photos: ScheduledPhoto[]) {
	const lookup: Record<string, ScheduledPhoto> = {};

	photos.forEach(p => {
		lookup[p.dateKey] = p;
	});

	return lookup;
}

function getUnpublishedPhotos() {
	const photoSets = useAppSelector(selectPhotoSets);

	const sets = photoSets
		?.map(s => new PhotoSet(s))
		.filter(s => s.publishGroups?.map(g => g.length > 0).length > 0);

	const unpublished: Record<number, Photo[]> = {};

	sets?.forEach(s => {
		s.getUnpublishedPhotos().forEach((p, pi) => {
			const photoArr = unpublished[pi];

			if (!photoArr) {
				unpublished[pi] = [p];
			} else {
				photoArr.push(p);
			}
		});
	});

	return Array.from(Object.values(unpublished)).flat();
}

function schedulePhotos(photos: Photo[]) {
	return photos.map((p, i) => {
		const date = moment();
		date.add(i + 1, 'days');

		return {
			photo: p,
			date,
			dayOfMonth: date.date(),
			dateKey: date.format('YYYY_MM_DD'),
		};
	});
}

function groupPhotosByMonth(photos: ScheduledPhoto[]) {
	const grouped: Record<string, ScheduledPhoto[]> = {};

	photos.forEach(p => {
		const month = p.date.format('YYYY-MM');

		if (!grouped[month]) {
			grouped[month] = [];
		}

		grouped[month].push(p);
	});

	return grouped;
}

function MonthPicker({
	date,
	setDate,
}: {
	date: string;
	setDate: (m: string) => void;
}) {
	const dateMoment = moment(date);
	return (
		<div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
			<a
				onClick={() =>
					setDate(dateMoment.add(-1, 'year').format('YYYY-MM'))
				}
			>
				Prev Year
			</a>
			<a
				onClick={() =>
					setDate(dateMoment.add(-1, 'month').format('YYYY-MM'))
				}
			>
				Prev
			</a>

			{dateMoment.format('MMMM YYYY')}

			<a
				onClick={() =>
					setDate(dateMoment.add(1, 'month').format('YYYY-MM'))
				}
			>
				Next
			</a>

			<a
				onClick={() =>
					setDate(dateMoment.add(-1, 'year').format('YYYY-MM'))
				}
			>
				Next Year
			</a>
		</div>
	);
}

function generateMonthDates(date: moment.Moment) {
	const startOfMonth = moment(date).startOf('month');

	const prevMonthDates = [...Array(startOfMonth.day()).keys()]
		.reverse()
		.map(i => undefined); // moment(startOfMonth).subtract(i + 1, 'days'));

	const dates: any[] = [...prevMonthDates];

	for (let i = 0; i < moment().daysInMonth(); i++) {
		dates.push(moment(startOfMonth).add(i, 'days'));
	}

	const weeks: moment.Moment[][] = [[], [], [], [], [], [], []];
	for (let i = 0; i < dates.length; i++) {
		const week = Math.floor(i / 7);

		weeks[week].push(dates[i]);
	}

	return weeks;
}

function weekOfMonth(m: moment.Moment) {
	return m.week() - moment(m).startOf('month').week() + 1;
}

function Calendar({
	date,
	monthlyPhotos,
}: {
	date: string;
	monthlyPhotos: Record<string, ScheduledPhoto>;
}) {
	const month = moment(date);

	const monthDates = generateMonthDates(month);

	return (
		<div
			style={{
				justifyContent: 'center',
				gap: '2px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<h1 style={{ marginTop: '35px	' }}>{month.format('MMMM YYYY')}</h1>

			<div
				style={{
					// justifyContent: 'center',
					gap: '2px',
					display: 'flex',
				}}
			>
				{['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(d => (
					<div
						key={d}
						style={{
							width: '150px',
							flexShrink: 0,
							padding: '1px 5px 3px 5px',
							color: '#ffffff',
							backgroundColor: 'rgb(0 0 0 / 50%)',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						{d}
					</div>
				))}
			</div>
			{monthDates.map((week, i) => (
				<div
					key={i}
					style={{
						gap: '2px',
						display: 'flex',
						//justifyContent: 'flex-end',
					}}
				>
					{week.map((d, j) => {
						const key = d?.format('YYYY_MM_DD') ?? j;

						const photo = monthlyPhotos[key];

						return (
							<div
								key={key}
								style={{
									position: 'relative',
									width: '150px',
									height: '150px',
									flexShrink: 0,
									//border: date ? 'solid pink 1px' : '',
								}}
								title={d?.format('DD MMM YYYY')}
							>
								{d && (
									<div
										style={{
											position: 'absolute',
											padding: '1px 5px 3px 5px',
											backgroundColor: '#000000aa',
											color: '#ffffff',
										}}
									>
										{d?.format('DD')}
									</div>
								)}

								{photo?.photo?.url && (
									<img
										style={{
											//opacity: 0.75,
											//backgroundColor: 'black',
											height: '150px',
											width: '150px',
											objectFit: 'cover',
											display: 'block',
										}}
										src={photo.photo.thumbnail}
									/>
								)}
							</div>
						);
					})}
				</div>
			))}
		</div>
	);
}

// a custom render function

// sets.map(s => (
// 				<div
// 					style={{
// 						display: 'flex',
// 						gap: '5px',
// 						backgroundColor: '#00000080',
// 						marginBottom: '5px',
// 					}}
// 				>
// 					<PhotoSetInfo set={s} />

// 					<div style={{ display: 'flex', gap: '5px' }}>
// 						{s.publishGroups.map(g => (
// 							<div style={{ position: 'relative' }}>
// 								<div
// 									style={{
// 										position: 'absolute',
// 										zIndex: 5,
// 										height: '100%',
// 										width: '100%',
// 										backgroundColor: '#00000050',
// 										display: 'flex',
// 										justifyContent: 'center',
// 										alignItems: 'center',
// 									}}
// 								>
// 									{g.length} photos
// 								</div>
// 								<img
// 									src={getThumbSrc(s, g[0])}
// 									style={{
// 										height: '150px',
// 									}}
// 								/>
// 							</div>
// 						))}
// 					</div>
// 				</div>
// 			))}
