import { getShortenedName, getThumbSrc } from '../../utils';
import { iPhotoSet } from '../../types/PhotoSet';

export function SetItem({ set, onClick }: { set: iPhotoSet; onClick: any }) {
	const p = set.photoList[0];
	const src = getThumbSrc(set, set.photoList[0]);

	return (
		<div
			style={{
				position: 'relative',
				height: '100px',
				width: '100px',
				flexShrink: 0,
				cursor: 'pointer',
			}}
			onClick={onClick}
		>
			<img
				key={set.name}
				style={{
					height: '100%',
					width: '100%',
					objectFit: 'cover',
				}}
				src={src}
			/>
			<div
				style={{
					fontSize: '14px',
					filter: 'drop-shadow(black 0px 0px 0.75rem)',
					position: 'absolute',
					height: '100%',
					width: '100%',
					zIndex: '10',
					top: '0',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					left: '0',
					color: 'white',
					backgroundColor: '#00000033',
					textAlign: 'center',
				}}
			>
				{getShortenedName(set).map(part => (
					<div key={part}>{part}</div>
				))}
			</div>
		</div>
	);
}
