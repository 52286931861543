import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from 'react-beautiful-dnd';
import { Dispatch, Key, SetStateAction, useEffect, useState } from 'react';
import { reorder, move } from './utilFuncs';
import { getItemStyle, getListStyle } from './styleFuncs';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import {
	selectPhotoSet,
	setPhotoSet,
	updatePublishGroups,
} from '../photoSet/photoSetSlice';

import { useParams } from 'react-router-dom';
import { SetSelector } from './SetSelector';
import { getImgSrc, getProgressiveSrc, getThumbSrc } from '../../utils';
import { PublishItem } from './PublishItem';
import { iPhoto } from '../../types/Photo';
import { iPhotoSet } from '../../types/PhotoSet';

export function PublishManager() {
	const dispatch = useAppDispatch();

	const { hash = '' } = useParams();

	useEffect(() => {}, []);
	useEffect(() => {
		if (hash) {
			dispatch(setPhotoSet(hash));
		}
	}, [hash]);

	const set = useAppSelector(selectPhotoSet) ?? null;

	const usedPhotos = set?.publishGroups?.flat(2).map(p => p.name) ?? [];

	const unusedPhotos =
		set?.photoList.filter(p1 => usedPhotos.indexOf(p1.name) === -1) ?? [];

	const [state, setState] = useState<iPhoto[][]>([]);
	const updateGroups = (newState: iPhoto[][]) =>
		dispatch(updatePublishGroups(newState.filter((g, i) => i > 0)));

	useEffect(() => {
		if (set) {
			const groups = set.publishGroups ?? [[]];

			setState([unusedPhotos, ...groups]);
		}
	}, [set]);

	async function onDragEnd(result: DropResult) {
		const { source, destination } = result;

		// dropped outside the list
		if (!destination) {
			return;
		}

		const sInd = +source.droppableId;
		const dInd = +destination.droppableId;

		if (sInd === dInd) {
			const items = reorder(state[sInd], source.index, destination.index);
			const newState: any[] = [...state];
			newState[sInd] = items;
			setState(newState);
			updateGroups(newState);
		} else {
			const result = move(state[sInd], state[dInd], source, destination);
			const newState = [...state];

			newState[sInd] = result[sInd];
			newState[dInd] = result[dInd];

			setState(newState);
			updateGroups(newState);
			//setState(newState.filter((group, i) => group.length || i === 0));
		}
	}

	async function deleteGroup(idx: number) {
		const newState = [...state];

		newState[0].push(...newState[idx]);

		newState.splice(idx, 1);

		if (idx === 1 && !newState[1]) {
			newState[1] = [];
		}

		setState(newState);
		updateGroups(newState);
	}

	const [activePhoto, setActivePhoto] = useState<iPhoto>();

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 33%',
					gap: '15px',
					padding: '15px',
				}}
			>
				<div>
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: 'auto 1fr auto auto',
							gap: '15px',
							position: 'sticky',
							top: '0px',
							backgroundColor: '#1d0c3b',
						}}
					>
						<SetSelector />

						{set && (
							<>
								{state[0] && (
									<Unpublished
										set={set}
										group={state[0]}
										ind={0}
										deleteGroup={deleteGroup}
										setActivePhoto={setActivePhoto}
									/>
								)}

								<button
									type='button'
									className='btn btn-outline-dark'
									onClick={() => {
										setState([...state, []]);
									}}
								>
									Add Group
								</button>
							</>
						)}
					</div>

					{set && (
						<div>
							{state
								.filter((g, i) => i > 0)
								.map((group, i) => (
									<PublishGroup
										key={`group_${i}`}
										set={set}
										group={group}
										ind={i + 1}
										deleteGroup={deleteGroup}
										setActivePhoto={setActivePhoto}
									/>
								))}
						</div>
					)}
				</div>
				{set && activePhoto && (
					<div style={{ width: '100%' }}>
						<img
							style={{ width: '100%' }}
							src={getProgressiveSrc(set, activePhoto)}
						/>
					</div>
				)}
			</div>
		</DragDropContext>
	);
}

type PublishGroupParams = {
	set: iPhotoSet;
	group: iPhoto[];
	ind: number;
	setActivePhoto: Function;
	deleteGroup: (ind: number) => void;
	styleOverride?: any;
	thumbStyleOverride?: any;
};

function PublishGroup({
	set,
	group,
	ind,
	deleteGroup,
	setActivePhoto,
}: PublishGroupParams) {
	return (
		<Droppable key={ind} droppableId={`${ind}`} direction='horizontal'>
			{(provided, snapshot) => (
				<div
					style={{
						margin: '10px 10px 10px 0px',
						width: '100%',
						flexShrink: 0,
					}}
				>
					<div
						style={{
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							gap: '5px',
						}}
					>
						<button
							style={{
								marginRight: '-12px',
							}}
							type='button'
							className='btn btn-outline-dark'
							onClick={() => false /*deleteGroup(ind)*/}
						>
							<i className='fa-solid fa-bars'></i>
						</button>
						<span>Group {ind}</span>
					</div>

					<div
						ref={provided.innerRef}
						style={{
							...getListStyle(snapshot.isDraggingOver),
							minHeight: '110px',
						}}
						{...provided.droppableProps}
					>
						{group.map((item, index) => (
							<Draggable
								key={item.name}
								draggableId={item.name}
								index={index}
							>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={{
											...getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											),
											flexShrink: '0',
										}}
										onClick={() => setActivePhoto(item)}
									>
										<PublishItem set={set} photo={item} />
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				</div>
			)}
		</Droppable>
	);
}

function Unpublished({ set, group, setActivePhoto }: PublishGroupParams) {
	return (
		<Droppable key={'0'} droppableId={'0'} direction='horizontal'>
			{(provided, snapshot) => (
				<div
					style={{
						width: '100%',
					}}
				>
					<div
						ref={provided.innerRef}
						style={{
							...getListStyle(snapshot.isDraggingOver),
							display: 'flex',
							overflow: 'auto hidden',
							minHeight: '110px',
						}}
						{...provided.droppableProps}
					>
						{group.map((item, index) => (
							<Draggable
								key={item.name}
								draggableId={item.name}
								index={index}
							>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={{
											...getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											),
											flexShrink: '0',
										}}
										onClick={() => setActivePhoto(item)}
									>
										<div
											style={{
												//display: 'flex',
												//justifyContent: 'space-around',
												height: '100px',
												flexShrink: '0',
											}}
										>
											<PublishItem
												set={set}
												photo={item}
											/>
										</div>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				</div>
			)}
		</Droppable>
	);
}
