import { useNavigate } from 'react-router-dom';
import { getImgSrc, getProgressiveSrc, getShortenedName } from '../../utils';
import styles from './PhotoSet.module.scss';
import { useAppDispatch } from '../../app/hooks';
import { processSet } from './photoSetSlice';
import { iPhotoSet } from '../../types/PhotoSet';

type PhotoSetThumbParams = {
	set: iPhotoSet;
};

export function PhotosetThumbLarge({ set }: PhotoSetThumbParams) {
	const navigate = useNavigate();
	return (
		<div
			className={styles.PhotoSetThumbLarge}
			onClick={() => navigate(`/photoset/${set.hash}`)}
		>
			<div className={styles.textContainer}>
				<div>
					{getShortenedName(set).map(part => (
						<div>{part}</div>
					))}
				</div>

				{!set.processed && <RenderProcessButton set={set} />}
			</div>

			<img
				className={`${styles.coverPhoto} ${
					set.processed ? '' : styles.notProcessed
				}`}
				src={
					set.processed
						? getProgressiveSrc(set, set.photoList[0])
						: getImgSrc(set, set.photoList[0])
				}
			/>

			<div className={styles.publishIcon}>
				<a
					style={{ cursor: 'pointer' }}
					role='button'
					onClick={e => {
						e.stopPropagation();

						navigate(`/publish/${set.hash}`);
					}}
				>
					<i className='fa-regular fa-calendar-days'></i>
				</a>
			</div>
		</div>
	);
}

function RenderProcessButton({ set }: { set: iPhotoSet }) {
	const dispatch = useAppDispatch();

	return (
		<a
			style={{ cursor: 'pointer' }}
			onClick={async e => {
				e.stopPropagation();
				await dispatch(processSet(set.hash));
			}}
		>
			Process Set <i className='fas fa-sync'></i>
		</a>
	);
}
