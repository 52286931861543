import { useAuth0 } from '@auth0/auth0-react';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import AuthButton from '../features/auth/authButton';
import { Scheduler } from '../features/publishManager/Scheduler';

function NavLink({ children, to, ...props }: LinkProps) {
	let resolved = useResolvedPath(to);
	let match = useMatch({ path: resolved.pathname, end: true });

	return (
		<li className='nav-item'>
			<Link
				className={`nav-link ${match ? 'active' : ''}`}
				to={to}
				{...props}
			>
				{children}
			</Link>
		</li>
	);
}

export const Navbar = () => {
	const { user } = useAuth0();

	console.log(user);

	return (
		<nav
			className='navbar navbar-expand navbar-dark bg-dark'
			style={{
				backgroundColor: '#00000080',
				//border: 'solid 1px #442d6b'
			}}
		>
			<div className='container-fluid'>
				<Link to='/' className='navbar-brand'>
					SuperJim Photography
				</Link>
				<div className='collapse navbar-collapse' id='navbarsExample02'>
					<ul className='navbar-nav me-auto'>
						{user?.['http://localhost:3000/roles']?.includes(
							'admin'
						) && (
							<>
								<NavLink to='/photoSets'>PhotoSets</NavLink>
								<NavLink to='/gallery'>Gallery</NavLink>
								<NavLink to='/publish'>Publish</NavLink>
								<NavLink to='/schedule'>Scheduler</NavLink>
							</>
						)}
					</ul>

					<div className='d-flex'>
						<AuthButton />
					</div>
				</div>
			</div>
		</nav>
	);
};
