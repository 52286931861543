import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { photosApi } from '../../services/api';
import { iPhotoSet } from '../../types/PhotoSet';

type StateStatuses = 'idle' | 'loading' | 'failed' | 'processing';
export interface PhotoSetsState {
	photoSets: iPhotoSet[];
	status: StateStatuses;
}

const initialState: PhotoSetsState = {
	photoSets: [],
	status: 'idle'
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const setPhotoSets = (forceRefetch = false) => async (dispatch: any) => {
	const sets = photosApi.endpoints.getPhotoSets.initiate(undefined, { forceRefetch: forceRefetch });

	await dispatch(sets);
};

export const photoSetsSlice = createSlice({
	name: 'photoSets',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setPhotoSetsStatus(state: PhotoSetsState, { payload: status }: PayloadAction<StateStatuses>) {
			state.status = status;
		}
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			//.addCase(setPhotoSets.fulfilled, (state, { payload: { photoSets = [] } }) => {
			//	state.photoSets = photoSets;
			//})
			//.addCase(setPhotoSets.rejected, (state, action) => {
			//	state.photoSets = [];
			//})
			//.addCase(setPhotoSets.pending, (state, action) => {
			//	state.photoSets = [];
			//});
			.addMatcher(photosApi.endpoints.getPhotoSets.matchFulfilled, (state, { payload: photoSets }) => {
				state.photoSets = photoSets;
			});
	},
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.photoSet.value)`
//public actions 
export const { setPhotoSetsStatus } = photoSetsSlice.actions;
export const selectPhotoSets = ({ photoSets: { photoSets } }: RootState) => {
	return photoSets;
};

export const selectPhotoSetStatus = ({ photoSets: { status } }: RootState) => status;

export const selectPhotoSetById = (hash: string) =>
	(state: RootState): iPhotoSet | null => {
		console.log(hash);
		const sets = selectPhotoSets(state);
		return sets.find(s => s.hash === hash) ?? null;
	};

export default photoSetsSlice.reducer;
