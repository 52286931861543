import debounce from 'debounce';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectPhotoSet } from '../photoSet/photoSetSlice';
import { iPhoto, Photo } from '../../types/Photo';

import styles from './Gallery.module.scss';

import { useNavigate, useLocation, NavigateFunction } from 'react-router-dom';
import { getGalleryRows } from './utils';
import { PhotoSet } from '../../types/PhotoSet';

export function Gallery({
	PhotoViewerComponent,
}: {
	PhotoViewerComponent: React.FC<{
		set: PhotoSet;
		photo: Photo;
		setPhoto: (photo: Photo | null) => void;
	}>;
}) {
	const loc = useLocation();
	console.log(loc);
	let navigate = useNavigate();

	const photoSet = useAppSelector(selectPhotoSet);

	if (!photoSet) {
		return null;
	}

	const [currentPhoto, setCurrentPhoto] = useState<Photo | null>(null);

	const [galleryWidth, setGalleryWidth] = useState(1024);

	const galleryRef = useRef<HTMLDivElement>(null);

	const [galleryRows, setGalleryRows] = useState<Photo[][]>([]);

	const setGalleryWidthDebounced = () =>
		setGalleryWidth(getGalleryWidth(galleryRef));

	const setPhoto = (photo: Photo | null) => {
		if (photo) {
			navigate(`#${encodeURIComponent(photo.name)}`);
		} else {
			if (history.state.idx) {
				history.back();
			} else {
				const prevUrl = window.location.href.split('#')[0];
				window.history.replaceState(null, '', `${prevUrl}`);
			}
		}

		setCurrentPhoto(photo);
	};

	useEffect(() => {
		if (galleryRef.current) {
			setGalleryWidthDebounced();
		}

		window.addEventListener('resize', setGalleryWidthDebounced);

		return () => {
			window.removeEventListener('resize', setGalleryWidthDebounced);
		};
	}, [galleryRef.current]);

	useEffect(() => {
		if (photoSet && galleryWidth) {
			setGalleryRows(getGalleryRows(photoSet.photoList, galleryWidth));
		}
	}, [photoSet.photoList, galleryWidth]);

	useEffect(() => {
		if (galleryRef.current) {
			setGalleryWidthDebounced();
		}

		window.addEventListener('resize', setGalleryWidthDebounced);

		return () => {
			window.removeEventListener('resize', setGalleryWidthDebounced);
		};
	}, [galleryRef.current]);

	useEffect(() => {
		if (loc?.hash) {
			const photoName = decodeURIComponent(loc?.hash?.slice(1));
			const photo = photoSet.photoList.find(p => p.name == photoName);

			if (photo) {
				setPhoto(photo);
			}
		} else {
			setCurrentPhoto(null);
		}

		window.addEventListener('resize', setGalleryWidthDebounced);

		return () => {
			window.removeEventListener('resize', setGalleryWidthDebounced);
		};
	}, [loc?.hash]);
	console.log(currentPhoto);
	return (
		<div className='gallery' ref={galleryRef}>
			{currentPhoto && (
				<PhotoViewerComponent
					set={photoSet}
					photo={currentPhoto}
					setPhoto={setPhoto}
				/>
			)}

			{galleryRows.map((r, ri) => GalleryRow(r, ri, navigate))}
		</div>
	);
}

function GalleryRow(row: Photo[], r: number, navigate: NavigateFunction) {
	return (
		<div key={r} style={{ display: 'flex', marginBottom: '2px' }}>
			{row.map((p, pi) => GalleryThumb(p, pi, navigate))}
		</div>
	);
}

function GalleryThumb(p: Photo, i: number, navigate: NavigateFunction) {
	return (
		<img
			className={styles.galleryItem}
			onClick={() => navigate(`#${encodeURIComponent(p.name)}`)}
			key={p.name}
			style={{
				marginLeft: i ? `${2}px` : '0px',
				height: p.thumbSize?.height,
				width: p.thumbSize?.width,
			}}
			src={p.thumbnail}
		/>
	);
}

let getGalleryWidth = (galleryRef: React.RefObject<HTMLDivElement>) => {
	return (
		document.documentElement?.clientWidth ??
		galleryRef.current?.clientWidth ??
		1024
	);
};
