export interface iThumbSize {
	width: number;
	height: number;
}

export class ThumbSize {
	private _width: number;
	private _height: number;
	private currHeight: number = 200;
	private currWidth: number = 200;

	get o_height(): number { return this._height; }
	get o_width(): number { return this._width; }
	get height(): number { return this.currHeight ?? 200; }
	set height(h: number) { this.currHeight = h; }

	get width(): number { return this.currWidth ?? 200; }
	set width(w: number) { this.currWidth = w; }

	reset() {
		this.currHeight = this._height;
		this.currWidth = this._width;
	}

	constructor({ width, height } = {} as iThumbSize) {
		this._width = width;
		this._height = height;

		this.reset();
	}
}
