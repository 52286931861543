import { Photo } from '../../types/Photo';
import { useAppDispatch } from '../../app/hooks';
import { getImgSrc } from '../../utils';
import {
	RatePhoto,
	//setPhoto,
	//nextPhoto,
	//prevPhoto,
} from '../photoSet/photoSetSlice';
import { useAuth0 } from '@auth0/auth0-react';

import styles from './photoViewer.module.scss';
import { useEffect, useRef } from 'react';
import { PhotoSet } from '../../types/PhotoSet';

export const PhotoViewer = ({
	set,
	photo,
	setPhoto,
}: {
	set: PhotoSet;
	photo: Photo;
	setPhoto: (photo: Photo | null) => void;
}) => {
	const currentDir: any = useRef(null);
	useEffect(() => {
		// Update the document title using the browser API
		if (currentDir.current) {
			currentDir.current.scrollIntoView({ block: 'center' });
		}
	});
	const dispatch = useAppDispatch();

	const { user } = useAuth0();

	const rating =
		photo.ratings.find(r => r.user.email === user?.email)?.rating ?? 0;

	const ratePhoto = async (rating: number) => {
		await dispatch(RatePhoto(user, photo, rating));
		//await dispatch(nextPhoto());
	};

	const closePhoto = async () => {
		setPhoto(null);

		//if (history.state.idx) {
		//	history.back();
		//} else {
		const prevUrl = window.location.href.split('#')[0];
		window.history.replaceState(null, '', `${prevUrl}`);
		//}
	};

	const findIdx = () =>
		set.photoList.findIndex((p: Photo) => p.name === photo.name) ?? -1;

	const nextPhoto = async () => {
		const idx = findIdx();
		console.log(idx);
		const photos = set!.photoList;

		if (idx == -1) {
			setPhoto(null);
		}

		const nextIdx = Math.min(photos.length - 1, idx + 1);
		const nextPhoto = set!.photoList[nextIdx];

		setPhoto(nextPhoto);
	};

	const prevPhoto = async () => {
		const idx = findIdx();

		if (idx == -1) {
			setPhoto(null);
		}

		const prevIdx = Math.max(0, idx - 1);
		const prevPhoto = set!.photoList[prevIdx];

		setPhoto(prevPhoto);
	};

	const setPhotoByIdx = (idx: number) => {
		const photo = set!.photoList[idx];

		setPhoto(photo);
	};

	const keyDown = async (e: any) => {
		switch (e.code) {
			case 'Numpad0':
				await ratePhoto(0);
				nextPhoto();
				break;
			case 'Numpad1':
				await ratePhoto(1);
				nextPhoto();
				break;
			case 'Numpad2':
				await ratePhoto(2);
				nextPhoto();
				break;
			case 'Numpad3':
				await ratePhoto(3);
				nextPhoto();
				break;
			case 'Numpad4':
				await ratePhoto(4);
				nextPhoto();
				break;
			case 'Numpad5':
				await ratePhoto(5);
				nextPhoto();
				break;
			case 'ArrowLeft':
				prevPhoto();
				break;
			case 'ArrowRight':
				nextPhoto();
				break;
			case 'Escape':
				setPhoto(null);
				break;
		}

		setFocus();
	};

	const mouseWheel = (e: any) => {
		if (e.deltaY > 0) {
			nextPhoto();
		} else {
			prevPhoto();
		}
	};

	useEffect(() => {
		setFocus();
	});

	const viewRef = useRef<HTMLDivElement>(null);

	const setFocus = () => {
		if (viewRef.current) {
			viewRef.current.focus();
		}
	};

	return (
		<div
			className={styles.photoViewer}
			ref={viewRef}
			onKeyDown={keyDown}
			onLoad={setFocus}
			onWheel={mouseWheel}
			tabIndex={-1}
		>
			<div className={styles.actionBar}>
				<div className={styles.ratingBar}>
					<a
						className={styles.clearRating}
						onClick={async () => ratePhoto(0)}
					>
						<i className='fa-solid fa-ban'></i>
					</a>
					{[1, 2, 3, 4, 5].map(i => (
						<a
							key={i}
							style={{ color: 'gold' }}
							className={styles.starRatingItem}
							onClick={() => ratePhoto(i)}
						>
							<i
								className={`${
									rating >= i ? 'fa-solid' : 'fa-regular'
								} fa-star fa-lg`}
							></i>
						</a>
					))}
				</div>

				<a className={styles.closeImage} onClick={closePhoto}>
					<i className='fa-solid fa-xmark  fa-lg'></i>
				</a>
			</div>

			<div
				style={{
					height: '100%',
					overflow: 'hidden',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				className={styles.photo}
			>
				<img src={photo.progressive} />
			</div>

			<div
				className={styles.setThumbs}
				style={{
					width: '100%',
					display: 'flex',
					overflow: 'auto hidden',
					height: '110px',
					gap: '2px',
					marginTop: '2px',
				}}
			>
				{set.photoList.map((p, idx) => (
					<div
						ref={p.name === photo.name ? currentDir : null}
						style={{
							opacity: p.name === photo.name ? '1' : '0.5',
							//display: 'flex',
							//justifyContent: 'space-around',
							height: '100px',
							flexShrink: '0',
						}}
						onClick={() => setPhotoByIdx(idx)}
					>
						<img src={p.thumbnail} />
					</div>
				))}
			</div>
		</div>
	);
};
