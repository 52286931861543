import { useEffect, useState } from 'react';

import { Gallery } from '../features/gallery/Gallery';
import { PhotoSetsList } from '../features/photoSets/PhotoSetsList';
import { Route, Routes } from 'react-router-dom';
import { PublishManager } from '../features/publishManager/publishManager';
import { Scheduler } from '../features/publishManager/Scheduler';
import { PhotoSet } from '../features/photoSet/PhotoSet';
import { AdminRoute } from './AdminRoute';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Callback } from '../features/auth/callback';
import { Privacy } from '../app/privacy';

export function AppRoutes() {
	useEffect(() => {}, []);
	const { user } = useAuth0();

	return (
		<Routes>
			<Route
				path='/'
				element={
					<div>
						Welcome
						{user ? ` ${user.name}` : ''}!
					</div>
				}
			/>
			<Route path='/privacy' element={<Privacy />} />
			<Route path='/callback' element={<Callback />} />
			<Route path='/PhotoSet/:hash' element={<PhotoSet />} />
			<Route path='*' element={<AuthenticatedRoutes />} />
		</Routes>
	);
}

const privateRoutes = () => {
	return (
		<Routes>
			<Route
				path='/PhotoSets/'
				element={<AdminRoute component={PhotoSetsList} />}
			/>
			<Route
				path='/publish/:hash'
				element={<AdminRoute component={PublishManager} />}
			/>
			<Route
				path='/publish'
				element={<AdminRoute component={PublishManager} />}
			/>
			<Route
				path='/schedule'
				element={<AdminRoute component={Scheduler} />}
			/>
		</Routes>
	);
};

export const AuthenticatedRoutes = withAuthenticationRequired(privateRoutes, {
	// Show a message while the user waits to be redirected to the login page.
	onRedirecting: () => <div>Redirecting you to the login page...</div>,
});
