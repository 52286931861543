import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

export const getItemStyle = (
	isDragging: boolean,
	draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
	// some basic styles to make the items look a bit nicer
	//userSelect: 'none',
	//padding: grid * 2,
	margin: `0 5px 0 0`,
	height: '100px',
	// change background colour if dragging
	//background: isDragging ? 'lightgreen' : 'grey',

	// styles we need to apply on draggables
	...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean) => ({
	background: isDraggingOver ? 'transparent' : '#170229',
	padding: 5,
	//margin: '10px 10px 10px 0px',
	width: '100%',
	height: 'auto',
	display: 'flex',
	overflow: 'auto hidden',
	boxShadow: isDraggingOver ? "0 0 2px rgb(68 217 232 / 90%), 0 0 4px rgb(68 217 232 / 40%), 0 0 1rem rgb(68 217 232 / 30%), 0 0 4rem rgb(68 217 232 / 10%)" : "0 0 2px rgb(111 66 193 / 90%), 0 0 4px rgb(111 66 193 / 40%), 0 0 1rem rgb(111 66 193 / 30%), 0 0 4rem rgb(111 66 193 / 10%)",
});