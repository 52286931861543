import { iPhotoSet, PhotoSet } from './PhotoSet';
import { Rating } from "./Rating";
import { ThumbSize, iThumbSize } from './ThumbSize';
import { ClassInterface } from './ulilityTypes';

export class PublicationInfo {
	publicationDate: Date | null;
	isPublished: boolean;
	constructor({ publicationDate, isPublished = false } = {} as PublicationInfo) {
		this.publicationDate = publicationDate ? new Date(publicationDate) : null;
		this.isPublished = isPublished;
	}
}


export interface iPhoto {
	name: string;
	ratings: Rating[];
	thumbSize: iThumbSize;
	publicationInfo: PublicationInfo;
	set?: iPhotoSet;
}

export class Photo implements iPhoto {
	name: string;
	ratings: Rating[];
	thumbSize: ThumbSize;
	publicationInfo: PublicationInfo;
	set?: PhotoSet;
	// getter functions	

	get url(): string {
		return this.getUrl('');
	}
	get progressive(): string {
		return this.getUrl('progressive');
	}
	get thumbnail(): string {
		return this.getUrl('thumbs');
	}

	get isPublished(): boolean {
		return this.publicationInfo.isPublished;
	}

	private getUrl(subDir: string): string {
		return this.set ? `/api/photos/${this.set.publicPath}/${subDir}/${this.name}`.replace('//', '/') : '';
	}

	constructor({ name, ratings = [], thumbSize, publicationInfo, set } = {} as iPhoto) {
		this.name = name;
		this.ratings = ratings.map(r => new Rating(r));
		this.publicationInfo = new PublicationInfo(publicationInfo);
		this.set = set ? new PhotoSet(set) : undefined;

		this.thumbSize = new ThumbSize(thumbSize);
	}
};
