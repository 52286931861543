import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './app/App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import {
	BrowserRouter,
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';

import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';

const {
	REACT_APP_AUTH0_DOMAIN,
	REACT_APP_AUTH0_CLIENT_ID,
	REACT_APP_AUTH0_CALLBACK_URL,
} = process.env;

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Auth0ProviderWithNavigate
				domain={REACT_APP_AUTH0_DOMAIN ?? ''}
				clientId={REACT_APP_AUTH0_CLIENT_ID ?? ''}
				authorizationParams={{
					redirectUri: REACT_APP_AUTH0_CALLBACK_URL,
				}}
			>
				<Provider store={store}>
					<App />
				</Provider>
			</Auth0ProviderWithNavigate>
		</BrowserRouter>
	</React.StrictMode>
);
