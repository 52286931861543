import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { selectPhotoSetById, setPhotoSets, setPhotoSetsStatus } from '../photoSets/photoSetsSlice';
import { photosApi } from '../../services/api';
import { iPhoto, Photo } from '../../types/Photo';
import { processPhotoSet, updatePhotoSet } from './photoSetAPI';
import { iPhotoSet, PhotoSet } from '../../types/PhotoSet';

export interface PhotoSetState {
	photoSet: iPhotoSet | null;
	//currentPhotoName: string | null;
	status: 'idle' | 'loading' | 'failed';
}

const initialState: PhotoSetState = {
	photoSet: null,
	//currentPhotoName: null,
	status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// type setPhotoSetThunkReturnType = PhotoSet;

// export const loadSet = (hash: string) => async (dispatch: any) => {
// 	const photos = await dispatch(photosApi.endpoints.getPhotoSet.initiate(hash)).unwrap();
// 	//photos?.forEach(async (photo: Photo) => await dispatch(precacheImage(photo))) ?? [];

// 	//dispatch(setPhotos(photos));
// };

export const updatePublishGroups = (groups: iPhoto[][]) => async (dispatch: any, getState: any) => {
	const state = getState();

	const set = selectPhotoSet(state);

	if (!set) {
		return;
	}

	const updatedSet: iPhotoSet = JSON.parse(JSON.stringify(set));
	updatedSet.publishGroups = groups;

	await updatePhotoSet(updatedSet.hash, updatedSet);
};

export const processSet = (hash: string) => async (dispatch: any) => {
	dispatch(setPhotoSetsStatus('processing'));
	const isSuccessful = await processPhotoSet(hash);

	if (isSuccessful) {
		await dispatch(setPhotoSets(true));
	}
	dispatch(setPhotoSetsStatus('idle'));
};

export const RatePhoto = (user: any, photo: iPhoto, rating: number) => async (dispatch: any, getState: any) => {
	dispatch(ratePhoto({ user, photo, rating }));
	const set = selectPhotoSet(getState());

	if (!set) {
		return;
	}

	const isSuccessful = await updatePhotoSet(set?.hash, set);

	if (isSuccessful) {
		await dispatch(setPhotoSets(true));
	}

	dispatch(setPhotoSetsStatus('idle'));
};

// const precacheImage = (photo: Photo) => async (dispatch: any) => {
// 	const img = new Image();

// 	img.onload = (im: any) => {
// 		const {
// 			currentTarget: { height, width },
// 		} = im;

// 		const thumbDimensions: ThumbSize = {
// 			id: photo.id,
// 			width,
// 			height
// 		};

// 		dispatch(setThumbSize(thumbDimensions));
// 	};

// 	img.src = photo.thumbnailLink;
// };
// export const setPhotoByName = (name: string) => async (dispatch: any, getState: any) => {

// 	const photo = await selectPhotoByName(name)(getState());

// 	if (!photo) {
// 		return;
// 	}

// 	dispatch(setPhoto(photo));
// };

// export const prevPhoto = () => async (dispatch: any, getState: any) => {
// 	const photo = await selectPrevPhoto(getState());

// 	if (!photo) {
// 		return;
// 	}

// 	const prevUrl = window.location.href.split('#')[0];
// 	window.history.replaceState(photo, '', `${prevUrl}#${encodeURIComponent(photo.name)}`);
// 	dispatch(setPhoto(photo));
// };

// export const nextPhoto = () => async (dispatch: any, getState: any) => {
// 	const photo = await selectNextPhoto(getState());

// 	if (!photo) {
// 		return;
// 	}
// 	const prevUrl = window.location.href.split('#')[0];
// 	window.history.replaceState(photo, '', `${prevUrl}#${encodeURIComponent(photo.name)}`);
// 	dispatch(setPhoto(photo));
// };

// export const setPhoto = createAsyncThunk<iPhoto | null, iPhoto | null, { state: RootState; }>(
// 	'photoSet/setPhoto',
// 	async (photo: iPhoto | null) => {
// 		return photo;
// 	}
// );


export const setPhotoSet = createAsyncThunk<iPhotoSet, string, { state: RootState; }>(
	'photoSet/setPhotoSet',
	async (hash: string, { dispatch, getState }) => {
		const photoSet = await dispatch(photosApi.endpoints.getPhotoSet.initiate(hash)).unwrap();

		return photoSet;
	}
);

type RatePhotoAction = {
	user: any;
	photo: iPhoto;
	rating: number;
};

export const photoSetSlice = createSlice({
	name: 'photoSet',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		ratePhoto(state: PhotoSetState, { payload: { user, photo, rating } }: PayloadAction<RatePhotoAction>) {
			const ratings = state.photoSet?.photoList.find(p => p.name === photo.name)?.ratings;

			if (!ratings) {
				return;
			}

			const currUserRating = ratings?.find(r => r.user.email === user.email);

			if (currUserRating) {
				currUserRating.rating = rating;
			} else {
				ratings.push({ user, rating });
			}
		}
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(setPhotoSet.fulfilled, (state, { payload: photoSet }) => {
				state.photoSet = photoSet;
			})
			.addCase(setPhotoSet.rejected, (state, action) => { state.photoSet = null; })
			.addCase(setPhotoSet.pending, (state, action) => { state.photoSet = null; });
		// .addCase(setPhoto.fulfilled, (state, { payload: photo }) => {
		// 	state.currentPhotoName = photo?.name ?? null;
		// })
		//.addCase(setPhoto.rejected, (state, action) => { state.currentPhotoName = null; })
		//.addCase(setPhoto.pending, (state, action) => { state.currentPhotoName = null; });
	},
});

//public actions 
const { ratePhoto } = photoSetSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.photoSet.value)`
export const selectPhotoSet = ({ photoSet: { photoSet } }: RootState) => photoSet ? new PhotoSet(photoSet as iPhotoSet) : null;

// export const selectCurrentPhoto = ({ photoSet: { photoSet, currentPhotoName } }: RootState) => {
// 	const curPhoto: iPhoto = photoSet?.photoList.find(p => p.name === currentPhotoName) as iPhoto;
// 	return new Photo({ ...curPhoto, set: photoSet as PhotoSet });
// };

// export const selectPrevPhoto = ({ photoSet: { photoSet, currentPhotoName } }: RootState) => {
// 	const idx = photoSet?.photoList.findIndex(p => p.name === currentPhotoName) ?? 0;
// 	const newIdx = Math.max(idx - 1, 0);
// 	return photoSet?.photoList[newIdx];
// };

// export const selectNextPhoto = ({ photoSet: { photoSet, currentPhotoName } }: RootState) => {
// 	const idx = photoSet?.photoList.findIndex(p => p.name === currentPhotoName) ?? -1;
// 	const newIdx = Math.min(idx + 1, (photoSet?.photoList.length ?? 1) - 1);
// 	return photoSet?.photoList[newIdx];
// };

export const selectPhotoByName = (name: string) => ({ photoSet: { photoSet } }: RootState) => {
	return photoSet?.photoList.find(p => p.name === name);
};

export default photoSetSlice.reducer;