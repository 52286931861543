import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectPhotoSets, setPhotoSets } from '../photoSets/photoSetsSlice';
import { iPhotoSet } from '../../types/PhotoSet';
import { SetItem } from './SetItem';
import { useNavigate, useParams } from 'react-router-dom';

export function SetSelector() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const photoSets = useAppSelector(selectPhotoSets);

	const [filter, setFilter] = useState('');
	const [showSetSelector, setShowSetSelector] = useState(false);

	useEffect(() => {
		dispatch(setPhotoSets());
	}, []);

	const filteredSets = filter.length
		? photoSets.filter(s => s.name.includes(filter))
		: photoSets;

	const { hash = null } = useParams();

	if (!photoSets.length) {
		return <div>Loading...</div>;
	}

	const selectedSet = hash
		? photoSets.find(s => s.hash === hash) ?? photoSets[0]
		: photoSets[0];

	return (
		<div style={{ position: 'relative' }}>
			<div style={{ marginTop: '5px' }}>
				<SetItem
					set={selectedSet}
					onClick={() => setShowSetSelector(!showSetSelector)}
				/>
			</div>
			{showSetSelector && (
				<>
					<div
						style={{
							position: 'fixed',
							zIndex: 2,
							top: '0px',
							left: '0px',
							bottom: '0px',
							right: '0px',
							backgroundColor: '#6f42c1f0',
						}}
					></div>
					<div
						style={{
							position: 'absolute',
							zIndex: 3,
							height: '600px',
							gridTemplateRows: 'auto 1fr',
							width: '800px',
							top: '5px',
							left: '112px',
						}}
					>
						<div className='form-group'>
							<input
								className='form-control'
								type='text'
								value={filter}
								onChange={e => setFilter(e.target.value.trim())}
								id='setFilter'
								placeholder='Search Sets'
							/>
						</div>

						<div
							style={{
								overflow: 'hidden',
								overflowY: 'auto',
								maxHeight: '600px',
								marginTop: '5px',
							}}
						>
							<div
								style={{
									display: 'grid',
									gridTemplateColumns:
										'repeat(auto-fill, 100px)',
									gap: '5px',
								}}
							>
								{filteredSets.map(s => (
									<SetItem
										key={s.name}
										set={s}
										onClick={() => {
											navigate(`/publish/${s.hash}`);
											setShowSetSelector(false);
										}}
									/>
								))}
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
