import '@fortawesome/fontawesome-free/js/all';

import './App.scss';

import { Navbar } from './Navbar';

import { AppRoutes } from '../routes/Routes';

function App() {
	return (
		<div className='App'>
			<Navbar />

			<div className='RouteContent scroller'>
				<AppRoutes />
			</div>
		</div>
	);
}

export default App;
