import { iPhoto } from '../../types/Photo';
import { get, post } from '../../utils';
import { iPhotoSet } from '../../types/PhotoSet';

export async function fetchPhotoSet(hash: string | null = null): Promise<iPhoto[] | null> {
	if (!hash) {
		return null;
	}

	const photos = await get<iPhoto[]>(`/getPhotoSet/${hash}`);

	return photos;
}

export async function processPhotoSet(hash: string | null = null): Promise<boolean> {
	if (!hash) {
		return false;
	}

	return await get<boolean>(`/processPhotoSet/${hash}`) ?? false;
}

export async function updatePhotoSet(hash: string | null = null, photoSet: iPhotoSet): Promise<boolean> {
	if (!hash) {
		return false;
	}

	return await post<boolean, iPhotoSet>(`/updatePhotoSet/${hash}`, photoSet) ?? false;
}


