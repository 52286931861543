import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
	selectPhotoSets,
	selectPhotoSetStatus,
	setPhotoSets,
} from './photoSetsSlice';

import styles from './PhotoSets.module.scss';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PhotosetThumbLarge } from '../photoSet/PhotoSetThumbLarge';
import { iPhotoSet } from '../../types/PhotoSet';

export function PhotoSetsList() {
	const photoSets = useAppSelector(selectPhotoSets);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setPhotoSets());
	}, []);

	return (
		<>
			<RenderProcessingOverlay />

			<RenderCardGroup photoSets={photoSets} />
		</>
	);
}

function RenderProcessingOverlay() {
	const photoSetsState = useAppSelector(selectPhotoSetStatus);

	if (photoSetsState !== 'processing') {
		return null;
	}

	return (
		<div className={styles.processingOverlay}>
			<h1>Processing Set</h1>
		</div>
	);
}

function RenderCardGroup({ photoSets }: { photoSets: iPhotoSet[] }) {
	const navigate = useNavigate();

	return (
		<div className={styles.photoSetList}>
			{photoSets.map((set: iPhotoSet) => (
				<PhotosetThumbLarge set={set} />
			))}
		</div>
	);
}
