import { Photo } from '../../types/Photo';

const maxHeight = 200;
const galleryGap = 2;

const
	setOffset = (p: Photo, offset: number) => {
		if (p.thumbSize) {
			p.thumbSize.width *= offset;
			p.thumbSize.height *= offset;
		}
	};


export const getGalleryRows = (photos: Photo[], galleryWidth: number) => {
	if (!photos.length) {
		return [];
	};

	const rows: Photo[][] = [];
	let rowNum = 0;

	const getRowHeights = (photos: Photo[]) => photos.map(p => p.thumbSize.height);

	for (const photo of photos) {
		photo.thumbSize.reset();

		let currRow = rows[rowNum];

		if (!currRow) {
			rows[rowNum] = currRow = [];
		}

		currRow.push(photo);

		const minHeight = Math.min(...[maxHeight, ...getRowHeights(currRow)]);

		currRow.forEach((photo: Photo) => {
			photo.thumbSize.width = (minHeight / photo.thumbSize.o_height) * photo.thumbSize.o_width;
			photo.thumbSize.height = minHeight;
		});

		const rowWidth = currRow
			.map(p => p.thumbSize.width)
			.reduce((totalWidth: number, width: number) => totalWidth + width + galleryGap, -galleryGap);

		if (rowWidth > galleryWidth) {
			const offset = galleryWidth / rowWidth;

			// break row and apply height offset to row items
			currRow.forEach(p => setOffset(p, offset));

			if (currRow.length >= 2) {
				rowNum++;
			}
		}
	}

	return rows;
};