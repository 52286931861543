import { iPhoto, Photo } from "./Photo";

export class PhotosList implements iPhotoList {
	photoList: iPhoto[];
	numPhotos: number;
	processed: boolean;
	publishGroups: Array<Array<Photo>> = [];

	constructor({ photoList = [], numPhotos, processed = false, publishGroups }: PhotosList) {
		this.photoList = photoList.map(p => new Photo(p));
		this.numPhotos = numPhotos ?? photoList.length;
		this.processed = processed;
		this.publishGroups = publishGroups ?? [];
	}
}

interface iPhotoList {
	photoList: iPhoto[];
	numPhotos: number;
	processed: boolean;
}

export interface iPhotoSet {
	name: string;
	dir: string;
	publicPath: string;
	hash: string;
	photoList: iPhoto[];

	processed: boolean;
	publishGroups: Array<Array<iPhoto>>;
}

export class PhotoSet implements iPhotoList {
	name: string;
	dir: string;
	publicPath: string;
	hash: string;
	photoList: Photo[];

	processed: boolean;
	publishGroups: Array<Array<iPhoto>> = [];

	get getShortenedName() {
		const nameArr = this.name.split(' > ');
		const len = nameArr.length;

		const shortennedName = nameArr.map((n: any) => n.trim()).slice(len - 2, len);

		return shortennedName;
	};
	get numPhotos() { return this.photoList.length; };

	getUnpublishedPhotos() {
		const unpublished = new Map<number, Photo[]>();

		this.publishGroups.forEach((g, gi) => {
			g.forEach((p, pi) => {
				const photo = new Photo({ ...p, set: this });
				if (!photo.isPublished) {
					const key = pi;

					const photos = unpublished.get(key);

					if (!photos) {
						unpublished.set(key, [photo]);
					} else {
						photos.push(photo);
					}
				}
			});
		});

		return Array.from(unpublished.values()).flat();
	};

	constructor({ name, dir, publicPath, hash, photoList = [], processed, publishGroups } = {} as iPhotoSet) {
		this.name = name;
		this.dir = dir;
		this.publicPath = publicPath;
		this.hash = hash;
		this.photoList = photoList.map(p => new Photo({ ...p, set: this })) ?? [];
		this.processed = processed;
		this.publishGroups = publishGroups;
	}
}