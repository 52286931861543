import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import photoSetsReducer from '../features/photoSets/photoSetsSlice';
import photoSetReducer from '../features/photoSet/photoSetSlice';
import { photosApi } from '../services/api';
import { setupListeners } from '@reduxjs/toolkit/query';

export const store = configureStore({
  reducer: {
    [photosApi.reducerPath]: photosApi.reducer,
    photoSets: photoSetsReducer,
    photoSet: photoSetReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(photosApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch);