import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function AuthButton() {
	const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

	return !isAuthenticated ? (
		<button
			className='btn'
			onClick={async () =>
				await loginWithRedirect({
					appState: {
						returnTo: '/',
					},
				})
			}
		>
			Log in
		</button>
	) : (
		<button
			className='btn'
			onClick={() => {
				logout({
					logoutParams: {
						returnTo: process.env.REACT_APP_SITE_URL,
						client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
					},
				});
			}}
		>
			Log out
		</button>
	);
}

export default AuthButton;
